<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="Choose Brand:" prop="brandId" >
<!--          <a-input v-model="form.brandId" placeholder="请输入品牌id关联{zb_brand.id}" />-->
          <a-select
            placeholder="--All Brand--"
            style="width: 100%"
            showSearch
            v-model="form.brandId"
            optionFilterProp="title"
            @change="changeBrandInfo"
          >
            <a-select-option v-for="item in brandListData" :key="item.id" :value="item.id" :title="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Choose Popups Type:" prop="type" >
          <a-select v-model="form.type">
            <a-select :key="0">Brand Popup</a-select>
            <a-select :key="1">Brand URL Popup</a-select>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Choose Popups Template:" prop="popValue" >
          <a-input v-model="form.popValue" placeholder="" />
          Brand Popup, the interface displays brand information, flavors and other information.
        </a-form-model-item>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">
          Save Setting
        </a-button>
      </a-form-model>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pagePop, listPop, delPop, updatePop, addPop} from '@/api/cigarette/pop'
import {getBrandList, listBrand} from "@/api/cigarette/brand";

export default {
  name: 'Pop',
  mixins: [],
  data () {
    return {
      submitLoading: false,
      form: {
        brandId: null,
        popValue: null,
        type: null
      },
      brandListData:[],
      rules:{}
    }
  },
  filters: {
  },
  created () {
    this.getList();
    this.listPopData(0);
    this.getBrandListData();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    changeBrandInfo(e) {
      this.listPopData(e);
    },
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePop(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPop(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    listPopData(brandId) {
      listPop({
        brandId:brandId,
      }).then(res => {
        if(res.data.length > 0) {
          this.form.type = res.data[0].type;
          this.form.popValue = res.data[0].popValue;
        } else {
          this.form.type = null;
          this.form.popValue = null;
        }
      })
    },

    getBrandListData() {
      listBrand({
        status:1,
        brandStatus:1
      }).then(res => {
        this.brandListData = res.data;
      })
    },
    /** 查询新-品牌弹窗设置列表 */
    getList () {
     pagePop(this.queryParam).then(response => {
      })
    },
  }
}
</script>
